<template>
  <div @mousedown="close" v-if="value" class="modal" tabindex="-1">
    <div @mousedown.stop class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <form @submit.prevent="addOrUpdate" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ formTitle }}</h5>
          <button
            @click="close"
            :class="{ 'is-busy': isLoading }"
            class="btn-close"
            type="button"
          ></button>
        </div>
        <div class="modal-body">
          <!-- FIELD: Name -->
          <div class="mb-3 form-floating">
            <input
              v-model.trim="form.name"
              ref="name"
              type="text"
              class="form-control"
              id="fname"
              placeholder="Example: Barbell backsquat"
            >
            <label for="fname">Name<sup class="text-danger">*</sup></label>
            <div class="form-text">Example: Barbell backsquat. Minimum 3 characters.</div>
          </div>

          <!-- FIELD: Instructions -->
          <div class="mb-3">
            <label for="fdescription">Instructions</label>
            <textarea
              v-model.trim="form.description"
              ref="description"
              rows="3"
              class="form-control"
              id="fdescription"
              placeholder="Insert instructions..."
            ></textarea>
          </div>

          <!-- FIELD: Source selector -->
          <div v-if="!isEditMode" :class="{ 'is-busy': isLoading }" class="mb-3">
            <select v-model.trim="form.videoSource" id="fvideo" class="form-select">
              <option value="" disabled selected>Add/upload video (optional)</option>
              <option value="youtube">Via YouTube/Vimeo</option>
              <option value="upload">Upload file</option>
              <option value="none">No video</option>
            </select>
          </div>

          <!-- FIELD: Video YOUTUBE/VIMEO -->
          <div
            v-if="form.videoSource === 'youtube'"
            :class="{ 'is-busy': isLoading }"
            class="mb-3"
          >
            <label for="fvideo">Video url</label>
            <input
              v-model.trim="form.videoUrl"
              ref="videoUrl"
              type="text"
              class="form-control"
              id="fvideo"
              placeholder="Insert video url... (see examples below)"
            >
          </div>

          <!-- FIELD: Url samples -->
          <VideoUrlSample v-if="form.videoSource === 'youtube'" class="mb-3"></VideoUrlSample>

          <!-- FIELD: Video UPLOAD/MP4 -->
          <div
            v-if="!isEditMode && form.videoSource === 'upload'"
            :class="{ 'is-busy': isLoading }"
            class="mb-3"
          >
            <input
              @change="handleFileChange"
              ref="file"
              type="file"
              class="form-control"
              :class="fileIsValid ? '' : 'is-invalid'"
              :disabled="isLoading"
            >
            <div class="invalid-feedback">Invalid file type and/or size.</div>
            <div class="form-text">
              Max file size: {{ maxSize | byteSize }}.<br>
              Allowed file types: {{ allowedExtensions }}.
            </div>
          </div>

          <!-- FIELD: Video info -->
          <div
            v-if="!isEditMode && form.videoSource === 'upload' && file"
            class="smaller py-1 mb-3 border-top border-bottom"
          >
            <strong>Size:</strong> {{ file.size | byteSize }}<br>
            <strong>Type:</strong> {{ file.type }}
          </div>

          <!-- FIELD: Error -->
          <ErrorMessage v-if="error" :error="error"></ErrorMessage>

          <!-- FIELD: Progress -->
          <div v-if="libraryIsUploading && !error" class="progress mb-3">
            <div
              :style="`width: ${libraryUploadPercentage}%`"
              :aria-valuenow="libraryUploadPercentage"
              aria-valuemin="0"
              aria-valuemax="100"
              class="progress-bar progress-bar-striped bg-info progress-bar-animated"
              role="progressbar"
            ></div>
          </div>
        </div>
        <div class="modal-footer">
          <SubmitButton
            :text="buttonTitle"
            :textBusy="'Please wait...'"
            :isLoading="isLoading"
            :disabled="isLoading || isSubmitDisabled"
            class="btn btn-primary"
          ></SubmitButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { deepCopy } from '@/core/util';

const formDefaultData = () => ({
  exerciseLibraryId: null,
  authorId: '',
  name: '',
  description: '',
  videoUrl: '',
  videoSource: '',
});

export default {
  name: 'ExerciseModal',
  components: {
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
    VideoUrlSample: () => import('@/elements/VideoUrlSample'),
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    exercise: {
      type: Object,
    },
  },
  computed: {
    ...mapState('auth', ['authUser']),
    ...mapState('library', ['libraryUploadPercentage']),
    ...mapGetters('library', ['libraryIsUploading']),
    formTitle() {
      return this.isEditMode ? 'Edit exercise' : 'Add exercise';
    },
    buttonTitle() {
      return this.isEditMode ? 'Update' : 'Submit';
    },
    fileIsValid() {
      const { file, maxSize, allowedFileTypes } = this;
      if (!file) return true;

      const { size, type } = file;
      if (size > maxSize) return false;
      if (!allowedFileTypes.find((val) => val.ext === type)) return false;

      return true;
    },
    allowedExtensions() {
      return this.allowedFileTypes.map((val) => val.lbl).join(', ');
    },
    isEditMode() {
      return !!this.exercise;
    },
    isSubmitDisabled() {
      const { name, videoUrl, videoSource } = this.form;
      const { file, fileIsValid, isEditMode } = this;
      if (name.length < 3) return true;
      if (!isEditMode) {
        if (videoSource === 'youtube' && videoUrl === '') return true;
        if (videoSource === 'upload' && (!file || !fileIsValid)) return true;
      }
      return false;
    },
  },
  methods: {
    close(forced = false) {
      if (this.isLoading && forced !== true) return;
      this.$emit('close');
    },
    handleFileChange() {
      const { files } = this.$refs.file;
      if (files.length) {
        const [file] = files;
        this.file = file;
      }
    },
    async addOrUpdate() {
      if (this.exercise) {
        await this.updateExercise();
      } else {
        await this.addExercise();
      }
    },
    async addExercise() {
      this.isLoading = true;
      this.error = null;

      try {
        const payload = {
          ...this.form,
          authorId: this.authUser.userId,
        };

        const { videoSource } = payload;
        if (videoSource === '' || videoSource === 'none') {
          payload.videoUrl = null;
          payload.videoSource = null;
        }

        const res = await this.$store.dispatch('library/addItem', payload);
        const { exerciseLibraryId } = res.data;

        if (videoSource === 'upload') {
          const { file } = this;
          const formData = new FormData();
          formData.append('file', file);
          formData.append('refId', exerciseLibraryId);
          await this.$store.dispatch('library/addItemFile', { file, formData });
        }

        this.close(true);
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    async updateExercise() {
      this.isLoading = true;
      this.error = null;

      try {
        const {
          exerciseLibraryId,
          authorId,
          name,
          description,
          videoUrl,
          videoSource,
        } = this.form;

        const payload = {
          exerciseLibraryId,
          authorId,
          name,
          description,
        };

        if (videoSource !== 'upload') {
          if (videoUrl && videoUrl.length) {
            payload.videoSource = 'youtube';
            payload.videoUrl = videoUrl;
          } else {
            payload.videoSource = null;
            payload.videoUrl = null;
          }
        }

        await this.$store.dispatch('library/addItem', payload);

        this.close(true);
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      form: null,
      file: null,
      maxSize: process.env.VUE_APP_API_MEDIA_MAX_SIZE * 1024 * 1024,
      allowedFileTypes: [
        {
          lbl: 'MP4',
          ext: 'video/mp4',
        },
        {
          lbl: 'MOV',
          ext: 'video/quicktime',
        },
      ],
    };
  },
  created() {
    if (this.isEditMode) {
      this.form = deepCopy(this.exercise);

      // When editing, force video source to youtube to enable user to add manual url
      if (this.form.videoSource !== 'upload') {
        this.form.videoSource = 'youtube';
      }
    } else {
      this.form = formDefaultData();
    }
  },
  mounted() {
    const instructionsPlaceholder = `Example:
Tempo: 2.2.1
Primary muscle: Glute
Secondary muscle: Calf
`;
    this.$refs.description.placeholder = instructionsPlaceholder;
  },
};
</script>

<style lang="scss" scoped>
.progress {
  height: .5rem;
}
</style>
